import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/ctadisclosureff.module.scss"
class CtaDisclosure extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {},language: {}});
  }
  componentDidMount(){
    this.props.data.then(data => {
      this.setState({data: data});
      this.setState({language: this.props.language});
    });
  }
  render() {
    return (
        <div id = {Styles.ctadisclaimer}>
          {(() => {
            switch (this.state.language) {
              case 'de':
                return <p>Diese kostenlose Erweiterung wird die Suchparameter dieses Browsers aktualisieren. Indem Sie fortfahren, erkennen Sie die  <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Nutzungsbedingungen</a> und <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Datenschutzerklärung</a>, an und es können Ihnen zusätzliche optionale Angebote präsentiert werden.</p>;
                break;
                case 'fr':
                  return <p>Cette extension gratuite mettra à jour les paramètres de recherche de ce navigateur. En continuant, vous reconnaissez la   <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>politique de confidentialité </a> et las <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>conditions de service</a>, et le fait que d'autres offres facultatives peuvent vous être présentées.</p>;
                  break;
                  case 'br':
                    return <p>Esta extensão gratuita irá atualizar as configurações de busca do seu navegador. Ao continuar, você concorda com a <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Política de Privacidade</a> e <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Termos de Uso</a>, e você será potencialmente apresentado a mais ofertas opcionais.</p>;
                    break;
              default:
                return <p>This free extension will update this browser's search settings. By continuing, you acknowledge the <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Privacy Policy</a> and <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Terms of Service</a>, and that you may be presented with more optional offers.</p>;
            }
          })()}
        </div>
    )
  }
}
export default CtaDisclosure
